<template>
<div class="collection">
  <router-link :to="`/guilds/${guild.id}`" class="collection-item avatar" v-for="guild in userGuilds" :key="guild.id">
    <img v-if="guild.icon" :src="discordCdn.guildIcon(guild)" alt="guild logo" class="circle">
    <span class="title">{{ guild.name }}</span>
  </router-link>
</div>
</template>

<script>
import {discordHttpClient, webserviceClient, discordCdn, discordDb, webserviceDb} from "@/app/shared/services";
import { ListGuildsRequest } from "@grpc-interface/supportmanager";
import M from "materialize-css";

export default {
  name: "Guilds",
  data() {
    return {
      userGuilds: [],
      smbGuilds: [],
      discordCdn,
    }
  },
  created() {
    // try getting discord guilds from cache, only use network if necessary
    discordDb.getGuilds()
      .then(guilds => {
        if (guilds.length === 0) {
          return Promise.reject();
        }
        this.userGuilds = guilds;
      })
      .catch(() => {
        discordHttpClient.get("/users/@me/guilds")
          .then((response) => {
            this.userGuilds = response.data;
            discordDb.insertGuilds(this.userGuilds);  // update cache
          })
      });

    // same for webservice guilds
    webserviceDb.getGuilds()
      .then(guilds => {
        if (guilds.length === 0) {
          return Promise.reject();
        }
        this.smbGuilds = guilds;
      })
      .catch(() => {
        const guilds_req = new ListGuildsRequest();
        guilds_req.setUserId()

        webserviceClient.listGuilds(guilds_req)
          .then((response) => {
            this.smbGuilds = response.getGuildsList();
            webserviceDb.insertGuilds(this.smbGuilds);  // update cache
          });
      })
  },
  mounted() {
    M.AutoInit();
  },
  methods: {},
}
</script>

<style scoped>

</style>
